<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="60px" :model="formLabelAlign" class="from">
            <el-form-item label="姓名：">
              <el-input placeholder="请输入姓名" size="mini" class="form-inp" v-model="formLabelAlign.username"></el-input>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个记录</span>
            <span class="span2">已选择 {{ more_List.length }} 个记录</span>
            <i @click="way_tableData" class="span_i el-icon-refresh">数据更新</i>
          </p>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="username" label="申请人姓名"></el-table-column>
            <el-table-column prop="dpt_id" label="申请人所在部门"></el-table-column>
            <el-table-column prop="vdate" label="日期"></el-table-column>
            <el-table-column prop="idcard" label="身份证号"></el-table-column>
            <el-table-column prop="address" label="详细地点"></el-table-column>
            <el-table-column prop="time_slot" label="详细时间段"></el-table-column>
            <el-table-column prop="vstyle" label="调阅方式"></el-table-column>
            <el-table-column prop="content" label="内容简述"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.status < 2" @click="open(scope.row.id)">审批</el-button>
                <el-button type="text" v-if="scope.row.status >= 2 && scope.row.result == null"
                  @click="openResult(scope.row.id)">添加结果</el-button>
                <el-button type="text" v-if="scope.row.status >= 2 && scope.row.result != null"
                  @click="openViewResult(scope.row.result)">查看结果</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page" />
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="username" label="申请人姓名"></el-table-column>
            <el-table-column prop="dpt_id" label="申请人所在部门"></el-table-column>
            <el-table-column prop="vdate" label="日期"></el-table-column>
            <el-table-column prop="idcard" label="身份证号"></el-table-column>
            <el-table-column prop="address" label="详细地点"></el-table-column>
            <el-table-column prop="time_slot" label="详细时间段"></el-table-column>
            <el-table-column prop="vstyle" label="调阅方式"></el-table-column>
            <el-table-column prop="content" label="内容简述"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.status < 2" @click="open(scope.row.id)">审批</el-button>
                <el-button type="text" v-if="scope.row.status >= 2" @click="openResult(scope.row.id)">添加结果</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>

        <el-dialog title="添加结果" :visible.sync="dialogFormResult">
          <el-form :model="formResult">
            <el-form-item label="结果内容">
              <el-input v-model="formResult.result" placeholder="请输入结果内容"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormResult = false">取 消</el-button>
            <el-button type="primary" @click="handelSumit()">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="查看结果" :visible.sync="dialogViewResult">
          <div class="results">
            <div>结果内容:</div>
            <div>{{ result }}</div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogViewResult = false">取 消</el-button>
          </div>
        </el-dialog>
      </div>
      <el-dialog title="添加结果" :visible.sync="dialogFormResult">
        <el-form :model="formResult">
          <el-form-item label="结果内容">
            <el-input v-model="formResult.result" placeholder="请输入结果内容"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormResult = false">取 消</el-button>
          <el-button type="primary" @click="handelSumit()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import table_minin from "../../assets/minin/table_minin";
import { videoList, videoCheck, addAddres } from "../../assets/request/api";
export default {
  mixins: [table_minin],
  data() {
    return {
      dialogVisible: false,
      dialogFormResult: false,
      dialogViewResult: false,
      formLabelAlign: {
        username: ""
      },
      formResult: {
        id: 0,
        result: ""
      },
      result: ""
    };
  },
  created() {
    this.url = videoList;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },

  methods: {
    openResult(id) {
      this.dialogFormResult = true;
      this.formResult.id = id;
    },
    handelSumit() {
      addAddres(this.formResult).then(res => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogFormResult = false;
          this.way_tableData();
        } else {
          this.$message({
            type: "info",
            message: res.msg
          });
        }
      });
    },
    open(id) {
      this.$confirm("此操作将审批这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data = {
            id: id
          };
          videoCheck(data).then(res => {
            if (res.err_code == 0) {
              this.$message({
                type: "success",
                message: res.msg
              });
              this.way_tableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    openViewResult(result) {
      this.dialogViewResult = true;
      this.result = result;
    },
    on_particulars(id) {
      this.$router.push({
        path: "/household/details",
        query: {
          id: id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

// ::v-deep .el-dialog {
//   min-width: none;
// }
.results {
  display: flex;
}
</style>
